<template>
  <div class="file">
    <span v-if="file.isFailed">
      <span class="loading-icon" v-tooltip="{content: 'Failed'}">❌</span>
    </span>
    <span v-else-if="file.isInvalid" v-tooltip="{content: 'Invalid'}">
      <span class="loading-icon">❌</span>
    </span>
    <span v-else class="loading-icon">
      <animated-checkmark ref="fileAnimation" :is-active="true" :is-completed-state="false"></animated-checkmark>
    </span>
    <span class="name">{{ getFileNameDisplay(file) }}</span>
    <span class="remove-action" v-if="canRemove"><span @click="$emit('remove')">remove</span></span>
    <div v-if="isInProgress" class="progress-bar" v-tooltip="{content: totalProgressRounded + '%'}">
      <div class="progress" :style="{width: progress + '%'}"></div>
    </div>
  </div>
</template>
<script>
import AnimatedCheckmark from '@common/elements/icons/AnimatedCheckmark.vue';

export default {
  name: 'uploaderFile',
  props: {
    file: File,
    index: Number,
    progress: Number,
    isCompleted: Boolean,
    isInProgress: Boolean,
  },
  watch: {
    isInProgress() {
      if (this.isInProgress) {
        this.$refs.fileAnimation.startPulsing();
      }
    },
    isCompleted() {
      if (this.isCompleted) {
        this.$refs.fileAnimation.toDone();
      }
    },
  },
  components: {AnimatedCheckmark},
  computed: {
    totalProgressRounded() {
      return Math.round(this.progress);
    },
    canRemove() {
      return !this.isInProgress && !this.isCompleted;
    },
  },
  methods: {
    getFileNameDisplay(file) {
      let name = file.name;
      let size = file.size;
      if (size > 1048576) {
        size = (size / 1048576).toFixed(2) + 'MB';
      } else if (size > 1024) {
        size = (size / 1024).toFixed(2) + 'KB';
      } else {
        size = size + 'B';
      }
      return `${name} (${size})`;
    },
  },
};
</script>
`

<style scoped lang="scss">
.file {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  padding: 10px 0;
  border-bottom: 1px solid $divider-line-light;
  width: 100%;
  position: relative;

  .name {
    z-index: 2;
    word-break: break-all;
  }

  .loading-icon {
    z-index: 2;
    padding-left: 10px;
    min-width: 28px;
    display: flex;
    justify-content: center;
  }
}

.progress-bar {
  width: 100%;
  position: absolute;
  height: 100%;
  overflow: hidden;

  .progress {
    height: 100%;
    background-color: $teal-200;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.remove-action {
  cursor: pointer;
  color: $link-color;
  margin-left: auto;
  z-index: 10;
  padding-right: 10px;

  :hover {
    text-decoration: underline;
  }
}
</style>
